import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import CategoryLinks from '../components/category-links'
import SiteMetaHook from '../hooks/site-meta-hook'
import CategoryHook from '../hooks/category-hook'
import PostList from '../components/post-list'
import Pagination from '../components/pagination'

export default ({ data }) => {
  const metaData = SiteMetaHook()
  const categoryData = CategoryHook()
  const title = 'Stories'
  const currentCategory = ''
  const pageInfo = data.allContentfulBlogPost.pageInfo
  const hrefBuilder = (pageNumber) => {
    return `/stories/${pageNumber > 1 ? pageNumber : ''}`
  }
  // console.log(pageInfo);
  return (
    <Layout>
      <div>
        <Helmet title={title} />
        <div className="wrap px-6 mx-auto py-8 md:px-20 md:pt-12 md:pb-10">
          <PostList data={data} currentCategory={currentCategory} />
          <Pagination pageInfo={pageInfo} hrefBuilder={hrefBuilder} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query StoryListQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "D MMMM, YYYY")
          heroImage {
            fluid(maxWidth: 870, maxHeight: 575, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid_withWebp
            }
            fixed(
              width: 870
              height: 575
              quality: 10
              resizingBehavior: FILL
            ) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
          category {
            slug
            title
            titleTw
          }
          body {
            body
            childMarkdownRemark {
              html
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
      }
    }
  }
`
